import React, { useState, useEffect } from "react";
import CreateAccount from "./AccountCreate";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
} from "@mui/x-data-grid";
import { Button, Menu, MenuItem, FormControlLabel, IconButton, Snackbar, Alert, Box, TextField,	Container, Tooltip, } from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import CustomGrid from "../common/Grid";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
//import { AccountData } from "./AccountData";
import EditAccount from "./AccountEdit";
import { GetAllAccounts, fetchDeleteAccountDetails, GetAccountsList } from "../../services/AccountService";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import { organizationsList } from "../organization/orgData";
import CustomColumnMenu from "../CustomColumnMenu";
import AccountCustomColumnMenu from "./AccountsColumnMenu";
import { useSnackbar } from "../../SnackbarContext";
import styles from "./AccountStle.module.scss";
// import { OrganizationId } from "../common/Home";
import { OrganizationId } from "../common/Home";
import FilterPopup from "../settings/Filterpopup";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import AddBoxIcon from '@mui/icons-material/AddBox';
import  Delete  from "../../assets/icons/delete.svg";

type InputFields = {
	AccountName: string;
	SearchInput: string;
};
type ErrorFields = Partial<InputFields>;
interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}

interface Model {
	AccountName: string | null;
	AdminType: string;
	AccountId: string;
}
// type InputFields = {
// 	AccountId: string,
// 	AccountName: string,
// 	AccountType: string,
// 	CreatedBy: string,
// 	CreatedDate: string,
// 	Organizationid: string,
// 	UpdatedBy: string,
// 	UpdatedDate: string,
// 	Active: Boolean,
// };
let email: any;

const AccountList = () => {
	const { t: translate } = useTranslation();
	const [showPopup, setShowPopup] = useState(false);
	const [models, setModels] = useState<Model[]>([]);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [loading, setLoading] = useState(false);
	const [accountidedit, setAccountIdEdit] = useState("");
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [emailiddelete, setemailiddelete] = useState("");
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const storedOrganizationString = localStorage.getItem("organization");
	const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString) : null;
	const Organizationid = OrganizationId;
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [totalcount, setTotalcount] = useState(0);
	const [searchText, setSearchText] = useState("");
	const { openSnackbar } = useSnackbar();
	const [orderByFields, setOrderByFields] = useState("");
	const [inputs, setInputs] = useState<InputFields>({
		AccountName: "",
		SearchInput: "",
	});
	const [sortModel, setSortModel] = useState([]);
	const [filters, setFilters] = useState([]);
	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) => (item.sort === "desc" ? `${item.field} desc` : item.field))
			.join(", ");

		setOrderByFields(orderByField);
		GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByField, filters);
	};
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 15,
	});
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});
	const [AccountDeleteDetails, setAccountDeleteDetails] = useState({
		AccountId: "",
		AccountName: "",
		AccountType: "",
		CreatedBy: "",
		CreatedDate: "",
		Organizationid: "",
		UpdatedBy: "",
		UpdatedDate: "",
		Active: Boolean(true),
	});

	const FetchAccounts = () => {
		setLoading(true);
		const skipcount = paginationModel.pageSize || 15;
		const limitcount = paginationModel.page * skipcount;
		const skips = limitcount;
		const top = paginationModel.pageSize;
		setskip(skips);
		settop(top);
		GetAccountsList(setModels, setLoading, Organizationid, skips, top, setTotalcount, orderByFields, filters);
	};

	useEffect(() => {
		setLoading(true);
		//GetAllAccounts(setModels, setLoading);
		FetchAccounts();
	}, [paginationModel]);

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	const handleDeleteClick = (emailId: string, accountid: string) => {
		setShowDeletePopup(true);
		setemailiddelete(emailId);
		setAccountIdEdit(accountid);
	};
	const handleeditclick = (accountid: string) => {
		setShowEditPopup(true);
		setAccountIdEdit(accountid);
	};
	const [email, setEmail] = useState<string>("");
	const [accountIdNew, setAccountIdNew] = useState<string>("");
	const columns: GridColDef[] = [

		{
			field: "AccountName",
			headerName: "Account Name",
			width: 350,
			flex: 1,
			disableColumnMenu: true,
			resizable: false,
		},
		{
			field: "CreatedDate",
			headerName: "Created Date",
			width: 350,
			flex: 1,
			disableColumnMenu: true,
			renderCell: (params) => {
				const createdDate = params.value ? new Date(params.value) : null;
				
				return createdDate ? createdDate.toLocaleDateString('en-GB') : "";
			  },
			resizable: false,
		},

		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			width: 200,
			flex: 1,
			disableColumnMenu: true,
			resizable: false,
			renderCell: (params) => {
				var emailId = params.row.CreatedBy || false;
				setEmail(emailId);
				const accountid = params.row.AccountId || false;
				setAccountIdNew(accountid)
				return (
					<Box className="qadpt-grd-act">

					<Tooltip arrow title="Edit Account">
                        <BorderColorOutlinedIcon style={{ color: "#7A7B7D" }}
                            className={styles.Addiconingrid}
                            onClick={() => handleeditclick(accountid)}
                        />
                    </Tooltip>
					
                    <Tooltip arrow title="Delete Account">
							<IconButton
                            onClick={() => handleDeleteClick(emailId,accountid)}
							>
									<i className='fal fa-trash-alt'></i>
								</IconButton>
                    </Tooltip>
					
                    
					</Box>
					
				);
			},
		},
	];

	const onPageChange = (newPage: number) => {
	};
	const onPageSizeChange = (newPageSize: number) => {
	};

	// useEffect(() => {
	// 	if (showDeletePopup) {
	// 		fetchAccountDetails(accountidedit);
	// 	}
	// }, [showDeletePopup]);
	// const fetchAccountDetails = async (id: any) => {
	// 	try {
	// 		const response = await fetch(`${adminUrl}/Account/GetAccountById?accountId=${id}`);
	// 		if (!response.ok) {
	// 			throw new Error("Network response was not ok");
	// 		}
	// 		const data = await response.json();
	// 		setAccountDeleteDetails({
	// 			AccountId: data.AccountId,
	// 			AccountName: data.AccountName,
	// 			AccountType: data.AccountType,
	// 			CreatedBy: data.CreatedBy,
	// 			CreatedDate: data.CreatedDate,
	// 			Organizationid: data.Organizationid,
	// 			UpdatedBy: data.UpdatedBy,
	// 			UpdatedDate: data.UpdatedDate,
	// 			Active: false,
	// 		});
	// 	} catch (error) {
	// 		console.error("Failed to fetch user details:", error);
	// 	}
	// };

	const handleDeleteAccount = () => {
		fetchDeleteAccountDetails(
			accountidedit,
			setLoading,
			setModels,
			setShowDeletePopup,
			Organizationid,
			skip,
			top,
			setTotalcount,
			setSnackbarMessage,
			setSnackbarSeverity,
			setSnackbarOpen,
			openSnackbar,
			orderByFields,
			filters
		);
		//DeleteAccountDetails(setLoading, setModels, setShowDeletePopup, AccountDeleteDetails);
	};

	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							//handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};


	const openPopup = () => {
		setShowPopup(true);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};

	const globalhandleSearch = (searchValue: any) => {
		const newFilter = {
			FieldName: "AccountName",
			ElementType: "string",
			Condition: "contains",
			Value: searchValue,
			IsCustomField: false,
		};
		let skips = 0;
		let top = 0;
		const skipCount = paginationModel.pageSize || 15;
		const limitCount = paginationModel.page * skipCount;
		top = paginationModel.pageSize;
		skips = limitCount;
		setskip(skips);
		settop(top);

		try {
			GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByFields, [newFilter]);
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
		}
	};
	const clearSearch = () => {
		setInputs({ ...inputs, SearchInput: "" });
		GetAccountsList(setModels, setLoading, Organizationid, skip, top, setTotalcount, orderByFields, filters);
	};
	const columnNames = columns.map((col) => col.headerName);
	const actionColumns = ["Actions", "Full Name"];
	const filteredColumnNames = columnNames
		.filter((name): name is string => name !== undefined && !actionColumns.includes(name))
		.map((name) => (name === "Role Name" ? name.replace(/\s+/g, "") : name));
	const handleApplyFilters = async (filters: any) => {
		// if (selectedAccount) {
		// 	setFilters(filters);
		// 	let skips: number = 0;
		// 	let top: number = 0;
		// 	const skipcount = paginationModel.pageSize || 15;
		// 	const limitcount = paginationModel.page * skipcount;
		// 	skips = paginationModel.pageSize;
		// 	top = limitcount;
		// 	const formattedFilters = filters.map((filter: any) => ({
		// 		FieldName: filter.column,
		// 		ElementType: "string",
		// 		Condition: filter.operator,
		// 		Value: filter.value,
		// 		IsCustomField: false,
		// 	}));
		// 	setskip(skips);
		// 	settop(top);
		// 	const orderByFields = "";
		// 	try {
		// 		("");
		// 	} catch (error) {
		// 		console.error("Failed to fetch user roles:", error);
		// 	}
		// } else {
		// 	setFilters(filters);
		// 	let skips: number = 0;
		// 	let top: number = 0;
		// 	const skipcount = paginationModel.pageSize || 15;
		// 	const limitcount = paginationModel.pageSize;
		// 	skips = paginationModel.page * skipcount;
		// 	top = limitcount;
		// 	const formattedFilters = filters.map((filter: any) => ({
		// 		FieldName: filter.column,
		// 		ElementType: "string",
		// 		Condition: filter.operator,
		// 		Value: filter.value,
		// 		IsCustomField: false,
		// 	}));
		// 	setskip(skips);
		// 	settop(top);
		// 	const orderByFields = "";
		// 	try {
		// 		("");
		// 	} catch (error) {
		// 		console.error("Failed to fetch user roles:", error);
		// 	}
		// }
	};

	return (
		<Container>
			<div className="qadpt-midpart setng-box">
				<div className="qadpt-content-block">
					<div>
						<div className="qadpt-head">
							<div className="qadpt-title-sec">
								<div className="qadpt-title">{translate('Account')}</div>
								<div className="qadpt-description">{translate('View & Manage your accounts here')}</div>
							</div>
							<div className="qadpt-right-part">
								<button
									onClick={openPopup}
									className="qadpt-memberButton"
								>
									<i className="fal fa-add-plus"></i>
									<span>{translate('Create Account')}</span>
								</button>
							</div>
							<div></div>
						</div>
						<div>
							{showPopup ? (
								<CreateAccount
									setModels={setModels}
									setLoading={setLoading}
									showPopup={showPopup}
									setShowPopup={setShowPopup}
									orderByField={orderByFields}
								/>
							) : (
								""
							)}
						</div>

						{showeditPopup ? (
							<EditAccount
								showEditPopup={showeditPopup}
								setShowEditPopup={setShowEditPopup}
								accountidedit={accountidedit}
								GetAllAccounts={GetAllAccounts}
								setModels={setModels}
								setLoading={setLoading}
								Organizationid={Organizationid}
								skip={skip}
								top={top}
								setTotalcount={setTotalcount}
								orderByField={orderByFields}
								filters={filters}
								setFilters={setFilters}
							/>
						) : (
							""
						)}

						<div>
							<Box className="qadpt-content-box">
								<div className="qadpt-src-flt">
									<div>
									<TextField
											className={`qadpt-teamsearch ${errors.SearchInput ? "qadpt-teamsearch-error" : ""}`}
											// style={{ right: "-650px" }}
											name="SearchInput"
											value={inputs.SearchInput}
											onChange={(e) => {
												const newValue = e.target.value;
												setInputs({ ...inputs, SearchInput: newValue });
												if (newValue === "") {
													clearSearch();
													GetAccountsList(
														setModels,
														setLoading,
														Organizationid,
														skip,
														top,
														setTotalcount,
														orderByFields,
														filters
													);
												}
											}}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
												  globalhandleSearch(inputs.SearchInput);
												}
											  }}
											placeholder={translate('Search accounts')}
											variant="outlined"
											error={!!errors.SearchInput}
											// sx={{
											// 	"& .MuiFormHelperText-root": {
											// 		color: errors.SearchInput ? "red" : "inherit",
											// 	},
											// 	"& .MuiOutlinedInput-root": {
											// 		padding: "0px",
											// 		borderRadius: "20px",
											// 	},
											// 	"& .MuiInputBase-input": {
											// 		height: "1em",
											// 		paddingLeft: "0px",
											// 	},
											// 	width: "200px",
											// 	marginBottom: "8px",
											// }}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<IconButton
															aria-label="search"
															onClick={() => globalhandleSearch(inputs.SearchInput)}
															onMouseDown={(event) => event.preventDefault()}
														>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												endAdornment: inputs.SearchInput && (
													<InputAdornment position="end">
														<IconButton
															aria-label="clear"
															onClick={() => {
																setInputs({ ...inputs, SearchInput: "" });
																clearSearch();
																GetAccountsList(
																	setModels,
																	setLoading,
																	Organizationid,
																	skip,
																	top,
																	setTotalcount,
																	orderByFields,
																	filters
																);
															}}
														>
															<ClearIcon />
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</div>{" "}
								</div>
								<DataGrid
									className="qadpt-account-grd"
									rows={models.length > 0 ? models : []} // Display models if available
									columns={columns}
									pagination
									paginationMode="server"
									slots={{
										columnMenu: (menuProps) =>
											menuProps.colDef.field === "AccountName" ? (
												<AccountCustomColumnMenu
													column={menuProps.colDef.field}
													setModels={setModels}
													setLoading={setLoading}
													skip={skip}
													top={top}
													OrganizationId={Organizationid}
													setTotalcount={setTotalcount}
													orderByFields={orderByFields}
													filters={filters}
													setFilters={setFilters}
													{...menuProps}
													options={models.map((model: any) => model.AccountName || "")}
													onSearch={handleSearch}
												/>
											) : null,
									}}
									getRowId={(row) => row.AccountId}
									paginationModel={paginationModel}
									onPaginationModelChange={(model) => {
										setPaginationModel(model);
										// FetchAccounts();
									}}
									rowCount={totalcount}
									pageSizeOptions={[15, 25, 50, 100]}
									localeText={{
										MuiTablePagination: {
											labelRowsPerPage: "Records Per Page",
										},
									}}
									disableRowSelectionOnClick={true}
									//loading={loading} // To show the DataGrid's built-in loading overlay
									sortModel={sortModel}
									onSortModelChange={handleSortModelChange}
								/>
								{models.length === 0 && (
									<div className="Loaderstyles">
										<img
											src={loader}
											alt="Spinner"
											className="LoaderSpinnerStyles"
										/>
									</div>
								)}
							</Box>
						</div>

						{showDeletePopup ? (
							<div className="qadpt-modal-overlay">
							<div className="qadpt-usrconfirm-popup qadpt-danger">
							<div>
								<div className="qadpt-icon">
									{/* <svg
										xmlns="http://www.w3.org/2000/svg"
										fill="#d05353"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 15.5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 17.5Zm1-7.5h-2V7h2Z" />
									</svg> */}
												<i className='fal fa-trash-alt'></i>
								</div>
								</div>
								<div className="qadpt-popup-title">Delete Account</div>
							
						
								<div className="qadpt-warning">
									The Account cannot be restored once it is deleted.
								</div>
								<div className="qadpt-buttons">
  									<button
   										 onClick={() => setShowDeletePopup(false)}
    									 className="qadpt-cancel-button"
  										>
    									Cancel
  									</button>

  									<button
    									className="qadpt-conform-button"
    									onClick={handleDeleteAccount}
  									>
   									 Delete
  									</button>
								</div>

						</div>
								</div>
						
						) : (
							""
						)}
					</div>
					<Snackbar
						open={snackbarOpen}
						autoHideDuration={4000}
						onClose={handleSnackbarClose}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
					>
						<Alert
							onClose={handleSnackbarClose}
							severity={snackbarSeverity}
						>
							{snackbarMessage}
						</Alert>
					</Snackbar>
				</div>
			</div>
		</Container>
	);
};

export default AccountList;