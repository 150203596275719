import React, { useState, ChangeEvent } from "react";
import { TextField, Button, Snackbar, Alert, Grid, Typography,FormControl } from "@mui/material";
import { useSnackbar } from "../../SnackbarContext";
import { useAuth } from "../auth/AuthProvider";
import { saveGuide,CheckGuideNameExists } from "../../services/GuideService";
import { accountId } from "../adminMenu/AdminMenu";
interface CreateNewPopupProps {
    setTourslist: (tours: any) => void;
    setLoading?: (loading: boolean) => void; 
    setShowPopup: (show: boolean) => void;
    showPopup: boolean;
    setTotalcount: (count: number) => void;
    setActiveTab: (tab: number) => void;
    activeTab: number;
    changeTab: () => void;
    name: string;
}

const CreateNewPopup: React.FC<CreateNewPopupProps> = (props) => {
    const {
        setTourslist,
        setLoading,
        setShowPopup,
        showPopup,
        setTotalcount,
        setActiveTab,
        changeTab,
        name
    } = props;

    const { openSnackbar } = useSnackbar();
    const { userDetails } = useAuth();
    const [inputs, setInputs] = useState({
        GuideName: "",
        TargetUrl: "",
    });
    const [errors, setErrors] = useState<Partial<Record<string, string>>>({});
    const [isValid, setIsValid] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let error = "";

        if (name === "GuideName" && value.trim().length < 3) {
            error = "Guide Name must be at least 3 characters.";
        }
        if (name === "TargetUrl") {
            const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
    

            if (value.length > 200) {
                error = "Target URL must be less than 200 characters.";
            }

            else if (/[^a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]/.test(value)) {
                error = "Target URL contains invalid characters.";
            }

            else if (!urlPattern.test(value)) {
                error = "Please enter a valid URL. E.g., https://example.com";
            }
        }
        setInputs((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: error }));
        setIsValid(
            inputs.GuideName.trim().length >= 3 &&
            !error && 
            inputs.TargetUrl.length <= 200 && 
            !errors.GuideName && 
            !errors.TargetUrl
        );
    };

    const handleSubmitTour = async (event: any) => {
        event.preventDefault();
        const newErrors: Partial<Record<string, string>> = {};
        let isValid = true;

        if (!inputs.GuideName) {
            newErrors.GuideName = "Guide Name is required.";
            isValid = false;
        } else if (inputs.GuideName.length < 3) {
            newErrors.GuideName = "Guide Name must be at least 3 characters.";
            isValid = false;
        }

        if (!inputs.TargetUrl) {
            newErrors.TargetUrl = "Target URL is required.";
            isValid = false;
        } else if (!/^https?:\/\/\S+\.\S+$/.test(inputs.TargetUrl)) {
            newErrors.TargetUrl = "Enter a valid URL.";
            isValid = false;
        }

        if (isValid) {
            const newGuide = {
                GuideId: " ",
                GuideType: name,
                Name: inputs.GuideName,
                Content: "",
                OrganizationId: userDetails?.OrganizationId,
                CreatedDate: new Date().toISOString(),
                CreatedBy: userDetails?.UserName,
                UpdatedBy: userDetails?.UserName,
                TargetUrl: inputs.TargetUrl,
                Frequency: "One-time",
                Segment: "All users",
                AccountId: accountId,
                GuideStatus: "Draft",
                GuideStep: [],
            };

            try {
                if (newGuide.OrganizationId) {
                    
                    const checkGuideNamevalidation = await CheckGuideNameExists(newGuide.Name, newGuide.AccountId,name);
                    if (checkGuideNamevalidation) {
                        newErrors.GuideName = "Guide Name Already Exists";
                        setErrors(newErrors);
                    }
                    const response = await saveGuide(newGuide);
                    if (response.Success) {
                        openSnackbar(`${inputs.GuideName} ${name}  created successfully. Please Check The Drafts`, "success");
                        window.open(newGuide.TargetUrl, '_blank');
                        changeTab(); 
                        setActiveTab(2)
                        setShowPopup(false);
                    } else {
                        throw new Error("Failed to save guide.");
                    }
                }
            } catch (error) {
                //openSnackbar(`Failed to create the ${name}.`, "error");
                setSnackbarSeverity("error");
            } finally {
                setSnackbarOpen(true);
            }
        } else {
            setErrors(newErrors);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            {showPopup && (
               <div className="qadpt-createpopup">
               <div className="qadpt-title-sec">
                 <div className="qadpt-title">Create {name}</div>
                 <div className="qadpt-sub-title">
                   Type in the {name} name and the URL of the webpage, where you want to create your {name}
                 </div>
                 <svg
                   onClick={() => setShowPopup(false)}
                   className="qadpt-closeicon"
                   xmlns="http://www.w3.org/2000/svg"
                   width="24"
                   height="24"
                   viewBox="0 0 50 50"
                 >
                   <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
                 </svg>
               </div>
               <div className="qadpt-createflds">
                 <Grid container spacing={2}>
                   <Grid item xs={12}>
                     <FormControl fullWidth required>
                       <label htmlFor="guide-name">{name} Name</label>
                       <TextField
                         name="GuideName"
                         required
                         value={inputs.GuideName}
                         onChange={handleChange}
                                    placeholder={name && `Enter ${name} Name`}
                         helperText={errors.GuideName}
                         variant="outlined"
                         error={!!errors.GuideName}
                         inputProps={{ maxLength: 50 }}
                         className="qadpt-createfield"
                       />
                     </FormControl>
                   </Grid>
                   <Grid item xs={12}>
                     <FormControl fullWidth required>
                       <label htmlFor="target-url">Target URL</label>
                       <TextField
                         id="target-url"
                         name="TargetUrl"
                         required
                         value={inputs.TargetUrl}
                         onChange={handleChange}
                         placeholder="E.g., https://example.com"
                         helperText={errors.TargetUrl}
                         variant="outlined"
                         error={!!errors.TargetUrl}
                         inputProps={{ maxLength: 200 }}
                         className="qadpt-createfield"
                       />
                     </FormControl>
                   </Grid>
                 </Grid>
               </div>
               <div className="qadpt-save-btn">
                 <Button
                   type="submit"
                   variant="contained"
                   onClick={handleSubmitTour}
                   color="primary"
                   disabled={!isValid}
                 >
                   Create & Open in Builder
                 </Button>
               </div>
             </div>
             
            )}
        </div>
    );
};

export default CreateNewPopup;
