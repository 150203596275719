import React, { useState, useEffect, ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { getAllOrganizations } from "../../services/OrganizationService";
import { SubmitCreateAccount } from "../../services/AccountService";
import { TextField, Select, MenuItem, Button, IconButton, Snackbar, Alert, Grid,FormControl } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "../../SnackbarContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import { useTranslation } from "react-i18next";

type InputFields = {
	AccountName: string;
};
type ErrorFields = Partial<InputFields>;

const CreateAccount = (props: any) => {
	const { t: translate } = useTranslation();
	const { setLoading, setModels, showPopup, setShowPopup, orderByField, filters } = props;
	// const [showPopup, setShowPopup] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const [skip, setskip] = useState("0");
	const [top, settop] = useState("100");
	const [totalcount, setTotalcount] = useState(0);
	const { openSnackbar } = useSnackbar();
	const [errors, setErrors] = useState<ErrorFields>({
		AccountName: "",
	});
	const navigate = useNavigate();	

	const generateCustomUserId = () => {
		const now = new Date();
		const day = String(now.getDate()).padStart(2, "0");
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const year = now.getFullYear();
		const datePart = `${day}${month}${year}`;
		const guidPart = uuidv4();
		return `${datePart}-${guidPart}`;
	};

	const [inputs, setInputs] = useState({
		AccountId: generateCustomUserId(),
		AccountName: "",
		CreatedBy: userDetails?.UserName,
		AccountType: "",
		CreatedDate: "",
		OrganizationId: userDetails?.OrganizationId,
		UpdatedBy: "",
		Active: Boolean(true),
	});	
	const [organizations, setOrganizations] = useState<any[]>([]);
	const [selectedOrganizationId, setSelectedOrganizationId] = useState(
		OrganizationId
	);

	const openPopup = () => {
		setShowPopup(true);
		handleOrganizationDropdownOpen();
	};
	const handleSubmit = (event: any) => {
		event.preventDefault();
	};
	const alphanumericRegex = /^[a-zA-Z0-9]*$/;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		let error = "";
		let processedValue = value.trimStart(); // Remove leading spaces

		if (name === "AccountType") {
			const selectedOrganization = organizations.find((org) => org.Name === value);
			if (selectedOrganization) {
				setInputs((values) => ({
					...values,
					AccountType: value,
					OrganizationId: OrganizationId, // Placeholder ID
				}));
			}
		} else if (name === "AccountName") {
			// Allow only letters and spaces, remove special characters, numbers, and trim leading spaces
			processedValue = value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");

			// Check if the length is less than 5 characters or if the input is only spaces
			if (processedValue.length < 3 || !processedValue.trim()) {
				error = "Account Name must be at least 3 characters and cannot be only spaces.";
			}
		}

		// Update the state with the processed value
		setInputs((prev) => ({ ...prev, [name]: processedValue }));

		// Set error state
		setErrors((prev) => ({ ...prev, [name]: error }));
	};

	// In CreateAccount component

	// const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
	// 	const { name, value } = event.target;
	// 	let error = "";
	// 	let processedValue = value;

	// 	if (name === "AccountType") {
	// 		const selectedOrganization = organizations.find((org) => org.Name === value);
	// 		if (selectedOrganization) {
	// 			setInputs((values) => ({
	// 				...values,
	// 				AccountType: value,
	// 				OrganizationId: "01072024-135246986-134dc53c-f123-4655-aa39-0529fa976863", // Placeholder ID
	// 			}));
	// 		}
	// 	} else if (name === "AccountName") {
	// 		// Allow only letters and spaces, remove special characters and numbers
	// 		processedValue = value.replace(/[^a-zA-Z\s]/g, "");

	// 		// Check if the length is less than 5 characters
	// 		if (processedValue.length < 5) {
	// 			error = "Account Name must be at least 5 characters.";
	// 		}
	// 	}

	// 	// Update the state with the processed value
	// 	setInputs((prev) => ({ ...prev, [name]: processedValue }));

	// 	// Set error state
	// 	setErrors((prev) => ({ ...prev, [name]: error }));
	// };

	const handleSubmitAccount = async (event: any) => {
		event.preventDefault();
		const newErrors: ErrorFields = {};
		let isValid = true;

		(Object.keys(inputs) as (keyof InputFields)[]).forEach((key) => {
			if (!inputs[key]) {
				// newErrors[key] = `${key} is required.`;
				// isValid = false;
			} else if (key === "AccountName") {
				if (inputs[key].length < 3) {
					newErrors[key] = "Account Name must be at least 3 characters.";
					isValid = false;
				}
				if (/[^a-zA-Z\s]/g.test(inputs[key])) {
					newErrors[key] = "Account Name can only contain letters and numbers.";
					isValid = false;
				}
			}
		});

		if (!inputs.AccountName) {
			setSnackbarMessage("Account Name is required.");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
			return;
		}
		const redirectUrl = `/${OrganizationId}/accounts`;
		setTimeout(() => {
			navigate(redirectUrl);
		}, 3000);
		const newInputs = {
			...inputs,
			AccountId: generateCustomUserId(), // Generate a new ID on submit
			Active: true,
		};

		setErrors(newErrors);
		if (isValid) {
			setLoading(true);
			try {
				SubmitCreateAccount(
					setLoading,
					setShowPopup,
					setModels,
					newInputs,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					openSnackbar,
					orderByField,
					filters
				);
				//openSnackbar("User  created successfully!", "success");
			} catch (error) {
				//openSnackbar("Failed to create user .", "error");
			}
		}
	};

	const isAccountNameValid = (name: string): boolean => {
		const processedValue = name.trimStart().replace(/[^a-zA-Z\s]/g, "");
		return processedValue.length >= 5 && processedValue.trim() !== "";
	};

	const handleOrganizationDropdownOpen = async () => {
		try {
			const response = await getAllOrganizations(setOrganizations, setLoading);
		} catch (error: any) {
			console.error("Error fetching organizations:", error);
			setSnackbarMessage(`Error fetching organizations: ${error.message}`);
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
		}
	};
	const handleSelectChange = (event: any) => {
		const selectedName = event.target.value;
		const selectedOrganization = organizations.find((org) => org.Name === selectedName);
		if (selectedOrganization) {
			setSelectedOrganizationId(OrganizationId);
		}

	};

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};

	return (
		<div>
			{showPopup ? (
				<div className="qadpt-modal-overlay">
				<div className="qadpt-accountcreatepopup">
				  <div className="qadpt-title-sec">
					<div className="qadpt-title">{translate('Create Account')}</div>
					<svg
					  onClick={() => setShowPopup(false)}
					  className="qadpt-closeicon"
					  xmlns="http://www.w3.org/2000/svg"
					  x="0px"
					  y="0px"
					  width="24"
					  height="24"
					  viewBox="0 0 50 50"
					>
					  <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
					</svg>
				  </div>
				  <div className="qadpt-accountcreatefield">
					<Grid container spacing={2}>
					  <Grid item xs={12}>
						<FormControl fullWidth required>
						  <label htmlFor="account-name">{translate('Account Name')}</label>
						  <TextField
							id="account-name"
							name="AccountName"
							required
							value={inputs.AccountName}
							onChange={handleChange}
							placeholder={translate('E.g., Google')}
							helperText={errors.AccountName}
							variant="outlined"
							error={!!errors.AccountName}
							inputProps={{ maxLength: 50 }}
							className="qadpt-acctfield"
						  />
						</FormControl>
					  </Grid>
					</Grid>
				  </div>
				  <div className="qadpt-account-buttons">
      <Button
        className={`qadpt-save-btn ${
          !isAccountNameValid(inputs.AccountName) ? 'invalid' : ''
        }`}
        onClick={handleSubmitAccount}
						//disabled={!isAccountNameValid(inputs.AccountName)}
      >
        {translate('Save')}
      </Button>
    </div>
				</div>
			  </div>
			  
			) : (
				""
			)}
			<Snackbar
				className="qadpt-accountalert"
				open={snackbarOpen}
				autoHideDuration={4000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					// sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default CreateAccount;
