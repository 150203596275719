import React, { useState, useEffect, useTransition, useRef } from "react";
import {
	GridColDef,
	GridRenderCellParams,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarDensitySelector,
	DataGrid,
	GridPaginationModel,
	GridColumnMenuProps,
	GridColumnsManagement,
} from "@mui/x-data-grid";
import {
	Button,
	Menu,
	MenuItem,
	FormControlLabel,
	Popover,
	Typography,
	IconButton,
	Switch,
	Container,
	Box,
	Snackbar,
	Alert,
	Divider,
	TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnRoundedIcon from "@mui/icons-material/ToggleOnRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import loader from "../../assets/loader.gif";
import { userData } from "./userData";
import CustomGrid from "../common/Grid";
import PersonOffSharpIcon from "@mui/icons-material/PersonOffSharp";
import axios from "axios";
import { MarkEmailRead } from "@mui/icons-material";
import EditUser from "./UserEdit";
import { useSnackbar } from "../../SnackbarContext";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyIcon from "@mui/icons-material/Key";
import { isSidebarOpen, subscribe } from "../adminMenu/sidemenustate";
import UserCreate from "./UserCreate";
import type { DatePickerProps } from "antd";
import Tooltip from "@mui/material/Tooltip";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Unblockaccount, Mail, Keyvertical, Lockopen } from "../../assets/icons/icons";
import {
	DeleteUserDetails,
	SubmitCreateUser,
	Submitdisableuser,
	fetchUserDataFromApi,
	fetchUsersList,
	confirmEmail,
	BlockUser,
	UnblockUser,
	deactivateUser,
	activateUser,
} from "../../services/UserService";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserPasswordReset from "./UserPasswordReset";
import UserCustomColumnMenu from "./UserCustomColumnMenu";
import { error } from "console";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterPopup from "../settings/Filterpopup";
import UserUnblock from "./UserUnblock";
import UserEnable from "./UserEnable";
import UserDisable from "./Userdisable";
import Search from "@mui/icons-material/Search";
import styles from "./UserStyles.module.scss";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Userblock from "./UserBlock";
import { useAuth } from "../auth/AuthProvider";
import {sendForgotPasswordEmail} from "../../services/ForgotPasswordService";
import { useTranslation } from "react-i18next";

type InputFields = {
	FirstName: string;
	LastName: string;
	EmailId: string;
	UserType: string;
	Password: string;
	ConfirmPassword: string;
	ContactNumber: string;
	Gender: string;
	DateofBirth: string;
	SearchInput: string;

};

type ErrorFields = Partial<InputFields>;
interface CustomDataGridProps extends React.ComponentProps<typeof DataGrid> {
	components?: {
		columnMenu?: React.ComponentType<GridColumnMenuProps>;
	};
}
interface Model {
	FullName: string | null;
	EmailId: string;
	ContactNumber: string | null;
	CreatedOn: string;
	Manager: string;
	UserId: string;
}
let isfltr: any;
let glblsrch = false;
let fieldName: any;
let filterss: any;

const UserList: React.FC = () => {
	const [models, setModels] = useState<Model[]>([]);
	const [loading, setLoading] = useState(false);
	const [emailiddelete, setemailiddelete] = useState("");
	const [useridedit, setUserIdEdit] = useState("");
	const [useridreset, setUserIdReset] = useState("");
	const [users, setUsers] = useState("");
	const [sidebarOpen, setSidebarOpen] = useState(isSidebarOpen());
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userName, setUserName] = useState("");
	const [helperText, setHelperText] = useState("	");
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
	const { signOut, userDetails } = useAuth();
	const [OrganizationId, setOrganizationId] = useState(userDetails?.OrganizationId??"");
	const [totalcount, setTotalcount] = useState(0);
	const { openSnackbar } = useSnackbar();
	const [usersEmails, setUsersEmails] = useState<string[]>([]);
	const [searchText, setSearchText] = useState("");
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 15,
	});
	const [inputs, setInputs] = useState<InputFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "User",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		SearchInput: "",
	});
	const [unBlockUserPopup, setUnBlockUserPopup] = useState(false);
	const [blockUserPopup, setBlockUserPopup] = useState(false);
	const [disableUserPopup, setDisableUserPopup] = useState(false);
	const [enableUserPopup, setEnableUserPopup] = useState(false);
	const [skip, setskip] = useState(0);
	const [top, settop] = useState(0);
	const [newpassword, setNewpassword] = useState("");
	const [orderByFields, setOrderByFields] = useState("");
	const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
	const [sortModel, setSortModel] = useState([]);
	const [columnname, setColumnname] = useState("");
	const [filters, setFilters] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
	const [errors, setErrors] = useState<ErrorFields>({
		FirstName: "",
		LastName: "",
		EmailId: "",
		UserType: "",
		Password: "",
		ConfirmPassword: "",
		ContactNumber: "",
		Gender: "",
		DateofBirth: "",
		SearchInput: "",
	});

	useEffect(() => {
		const userInfoString = localStorage.getItem("userInfo");	
		if (userInfoString) { 
			try {
				const userInfo = JSON.parse(userInfoString);	
				if (userInfo['user']) {
					const parsedUser = JSON.parse(userInfo['user']);
					if (parsedUser) {
						const OrgId = parsedUser.OrganizationId ?? '';
						setOrganizationId(OrgId);
					}
				}
			} catch (error) {
				console.error("Error parsing userInfo: ", error);
			}
		}
		
	}, []);
	const [selectedRows, setSelectedRows] = useState([]);

	const handleSelectionChange = (newSelection: any) => {
		setSelectedRows(newSelection);
	};

	const handleSortModelChange = (model: any) => {
		setSortModel(model);
		const orderByField = model
			.map((item: any) =>
				item.sort === "" || item.sort === "asc" || (item.sort === "desc" && item.field === "FullName")
					? `FirstName desc`
					: item.sort === "desc" && item.field !== "FullName"
					? `${item.field} desc`
					: item.field
			)
			.join(", ");
		setOrderByFields(orderByField);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByField, filters);
	};

	let skipss: Number;
	let topss: Number;

	const handleFirstnameFocus = () => {
		if (firstName.length < 2) {
			setHelperText("First Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleLastnameFocus = () => {
		if (lastName.length < 2) {
			setHelperText("Last Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleUsernameFocus = () => {
		if (userName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleEmailFocus = () => {
		if (firstName.length < 2) {
			setHelperText("User Name is required");
		} else {
			setHelperText("");
		}
	};
	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	};
	const handleSearch = (value: string[]) => {
		setSearchText(value.join(" "));
	};
	const popupRef = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => { 
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) { 
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
	const [emailconfirmed, setEmailConfirmed] = useState<any>("");
	
	const [blocked, setBlocked] = useState<any>("");
	const [activate, setActivate] = useState<any>("");
	const [isactive, setIsActive] = useState<any>("");
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [activeRowId, setActiveRowId] = useState<null | string>(null);
		const handleMenuClick = (event: React.MouseEvent<HTMLElement>,rowId: string) => {
			setAnchorEl(event.currentTarget);
			setActiveRowId(rowId);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
		setActiveRowId(null);
	};
	const handleActionClick = (action: string,userIdNew:string,emailNew:any) => {
		switch (action) {
			case "confirmEmail":
				handleMailClick(userIdNew);
				break;
			case "blockUser":
				Blockuser(userIdNew);
				break;
			case "unblockUser":
				UnBlockuser(userIdNew);
				break;
			case "activateUser":
				Activateuser(userIdNew);
				break;
			case "deactivateUser":
				DeActivateuser(userIdNew);
				break;
			case "resetPassword":
				ResetPassword(emailNew);
				break;
			case "deleteUser":
				handleDeleteClick(emailNew);
				break;
			default:
				break;
		}
		handleMenuClose();
	};
	const handleMailClick = (userid: string) => {
		try {
			if (emailconfirmed) {
				openSnackbar("Email is already confirmed", "error");
			} else {
				confirmEmail(
					setModels,
					setLoading,
					OrganizationId,
					skip,
					top,
					setTotalcount,
					userid,
					openSnackbar,
					orderByFields,
					filters
				);

			}

			setLoading(false);
		} catch (error) {}
	};
	const Blockuser = (userid: string) => {
		setUserIdEdit(userid);
		setBlockUserPopup(true);
	};
	const UnBlockuser = (userid: string) => {
		setUserIdEdit(userid);
		setUnBlockUserPopup(true);
	};
	const Activateuser = (userid: string) => {
		setUserIdEdit(userid);
		setEnableUserPopup(true);
	};
	const DeActivateuser = (userid: string) => {
		setUserIdEdit(userid);
		setDisableUserPopup(true);
	};
	const ResetPassword = async(emailId: string) => {
		try {
			const response = await sendForgotPasswordEmail(emailId);  
			if (response.Success) { 
				openSnackbar(`Reset Password link Sent to ${emailId} Successfully`,"success");
			} else {
				openSnackbar(`failed to send Reset Password link  to ${emailId} `,"error");
			}
		} catch (error) {
			console.error("Error sending email", error);
		}
		
	};

	const handleDeleteClick = (emailId: string) => {
		setShowDeletePopup(true);
		setemailiddelete(emailId);
	};
	const handleeditclick = (userid: string) => {
		setShowEditPopup(true);
		setUserIdEdit(userid);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setShowDeletePopup(false);
	};


	
	const columns: GridColDef[] = [
		{
			field: "FullName",
			headerName: "Full Name",
			// width: sidebarOpen ? 200 : 200,
			resizable: false,
			filterable: false,
			disableColumnMenu: true,
			sortable: true,
			renderCell: (params) => {
				const { FirstName, LastName } = params.row;
				return (
					<div>
						{FirstName} {LastName}
					</div>
				);
			},
		},
		{
			field: "EmailId",
			headerName: "Email Address",
			// width: sidebarOpen ? 250 : 250,
			disableColumnMenu: true,
			resizable: false,
		},
		{
			field: "ContactNumber",
			headerName: "Contact Number",
			// width: sidebarOpen ? 180 : 230,
			disableColumnMenu: true,
			resizable: false,
		},
		
		{
			field: "AdminDeactivated",
			headerName: " Status",
			// width: 100,
			resizable: false,
			renderCell: (params) => {
				const { AdminDeactivated, IsBlocked, EmailConfirmed } = params.row;
				return (
					<div className={IsBlocked ? "st-blo" : !EmailConfirmed ? "st-pen" : AdminDeactivated ? "st-ina" : "st-act"}>
						{IsBlocked ? "Blocked" : !EmailConfirmed ? "Pending" : AdminDeactivated ? "Inactive" : "Active"}
					</div>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			sortable: false,
			resizable: false,
			disableColumnMenu: true,
			renderCell: (params) => {
				const emailConfirmed = params.row.EmailConfirmed || false;
				const emailId = params.row.EmailId || false;
				const userid = params.row.UserId || false;
				const isblocked = params.row.IsBlocked || false;
				const activateuser = params.row.AdminDeactivated || false;
				const IsActive = params.row.IsActive || false;
				return (
					<Box className="qadpt-grd-act">
                   {isblocked ? (
					<Tooltip
						title="Edit"
						arrow
						placement="top"
					>
						<IconButton
							className="qadpt-grdicon"
							aria-label="edit"
							onClick={() => handleeditclick(userid)}
							disabled
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip
						title="Edit"
						arrow
						placement="top"
					>
						<IconButton
							className="qadpt-grdicon"
							aria-label="edit"
							onClick={() => handleeditclick(userid)}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}

						
						<Tooltip arrow title="actions">
                        <MoreVertIcon
                            className={styles.Addiconingrid}
                            onClick={(event:any) => handleMenuClick(event,userid)}
                        />
						</Tooltip>
						{anchorEl && activeRowId === userid &&(	
							<div
							className="qadpt-menupopup" ref={popupRef}>
					
					{!activateuser && !isblocked ? (
						<div onClick={() => handleActionClick("resetPassword",userid,emailId)}>
							<FormControlLabel
								control={
									<Tooltip
										title="Reset Password"
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="reset password"
										>
											<div className="qadpt-actionpopup">
												<KeyIcon style={{ transform: "rotate(90deg)" }} />
												<span>Reset Password</span>
											</div>
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</div>
					) : activateuser && !emailConfirmed ? (
						<div onClick={() => handleActionClick("resetPassword",userid,emailId)}>
							<FormControlLabel
								control={
									<Tooltip
										title="Reset Password"
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="reset password"
											onClick={() => ResetPassword(userid)}
										>
											<div className="qadpt-actionpopup">
												<KeyIcon style={{ transform: "rotate(90deg)" }} />
												<span>Reset Password</span>
											</div>
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</div>
					) : (
						""
					)}
					{isblocked ? (
						<div onClick={() => handleActionClick("unblockUser",userid,emailId)}>
							<FormControlLabel
								control={
									<Tooltip
										title={isblocked ? "Unblock User" : "Block User"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label={isblocked ? "unblock user" : "block user"}
											onClick={() => {
												if (isblocked) {
													UnBlockuser(userid);
												} else {
													Blockuser(userid);
												}
											}}
										>
											{" "}
											{isblocked ? (
												<div className="qadpt-actionpopup">
													<>
														<LockOpenOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}> Unblock User</span>
													</>
												</div>
											) : emailConfirmed ? (
												""
											) : (
												<div className="qadpt-actionpopup">
													<>
														<LockOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}>Block User</span>
													</>
												</div>
											)}
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</div>
					) : !emailConfirmed ? (
						""
					) : (
						<div onClick={() => handleActionClick("blockUser",userid,emailId)}>
							<FormControlLabel
								control={
									<Tooltip
										title={isblocked ? "Unblock User" : "Block User"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label={isblocked ? "unblock user" : "block user"}
											onClick={() => {
												if (isblocked) {
													//UnBlockuser(userid);
												} else {
													Blockuser(userid);
												}
											}}
										>
											{" "}
											{isblocked ? (
												// <div className="qadpt-actionpopup">
												// 	<>
												// 		<LockOpenOutlinedIcon
												// 			className={styles["qadpt-iconspositions"]}
												// 			style={{ color: "#1C1B1F" }}
												// 		/>
												// 		<span style={{ color: "#202224" }}> Unblock User</span>
												// 	</>
												// </div>
												""
											) : (
												<div className="qadpt-actionpopup">
													<>
														<LockOutlinedIcon
															className={styles["qadpt-iconspositions"]}
															style={{ color: "#1C1B1F" }}
														/>
														<span style={{ color: "#202224" }}>Block User</span>
													</>
												</div>
											)}
										</IconButton>
									</Tooltip>
								}
								label=""
							/>
						</div>
					)}
					{isblocked ? (
						""
					) : emailConfirmed ? (
						""
					) : (
						<div onClick={() => handleActionClick("confirmEmail",userid,emailId)}>
							<FormControlLabel
								control={
									<Tooltip
										title={!emailConfirmed ? "Confirm Email" : "Email Confirmed"}
										arrow
										placement="top"
									>
										<IconButton
											color="secondary"
											aria-label="email"
											// onClick={() => handleMailClick(userid)}
										>
											<div className="qadpt-actionpopup">
												{/* {emailConfirmed ? ( */}
												<>
													<MailIcon />
													<span>confirm Email</span>
												</>
												{/* ) : (
													<>
														<DraftsIcon />
														<span>confirm Email</span>
													</>
												)} */}
											</div>
										</IconButton>
									</Tooltip>
								}
								label={""}
							/>
						</div>
					)}
					{isblocked ? (
						""
					) : (
						<div>
							{!activateuser && emailConfirmed  ? (
								<div onClick={() => handleActionClick("deactivateUser",userid,emailId)}>
									<FormControlLabel
										control={
											<Tooltip
												title={!activateuser ? "Deactivate User" : "Activate User"}
												arrow
												placement="top"
											>
												<IconButton
													className={styles["qadpt-iconButton"]}
													color="primary"
													aria-label={!activateuser ? "deactivate user" : "activate user"}
													onClick={() => {
														if (!activateuser) {
															DeActivateuser(userid);
														}
														
													}}
												>
													<div className="qadpt-actionpopup">
														<>
															<NoAccountsOutlinedIcon
																className={styles["qadpt-iconspositions"]}
																style={{ color: "#1C1B1F" }}
															/>
															<span style={{ color: "#202224" }}> Disable User</span>
														</>
													</div>
													
												</IconButton>
											</Tooltip>
										}
										label=""
									/>
								</div>
							) : !emailConfirmed ? (
								""
							) : (
								<div onClick={() => handleActionClick("activateUser",userid,emailId)}>
									<FormControlLabel
										control={
											<Tooltip
												title={!activateuser ? "Deactivate User" : "Activate User"}
												arrow
												placement="top"
											>
												<IconButton
													className={styles["qadpt-iconButton"]}
													color="primary"
													aria-label={!activateuser ? "deactivate user" : "activate user"}
													onClick={() => {
														// if (!activateuser) {
														// 	DeActivateuser(userid);
														// } else
														if (activateuser) {
															Activateuser(userid);
														}
													}}
												>
													
													<div className="qadpt-actionpopup">
														<>
															<AccountCircleOutlinedIcon
																className={styles["qadpt-iconspositions"]}
																style={{ color: "#1C1B1F" }}
															/>
															<span style={{ color: "#202224" }}> Enable User</span>
														</>
													</div>
												</IconButton>
											</Tooltip>
										}
										label=""
									/>
								</div>
							)}
						</div>
					)}
					
							</div>
						)}
						

						
                </Box>
				);
			},
		},
	];
	const clearSearch = () => {
		let filt: any[] = [];
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filt);
		setFilters([]);
	};

	const globalhandleSearch = (searchValue: any) => {
		const isNumber = /^\d+$/.test(searchValue);

		const filters = [
			{
				FieldName: "EmailId",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
			{
				FieldName: "UserName",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
			// {
			// 	FieldName: "LastName",
			// 	ElementType: "string",
			// 	Condition: "contains",
			// 	Value: searchValue,
			// 	IsCustomField: false,
			// },
			{
				FieldName: "ContactNumber",
				ElementType: "string",
				Condition: "contains",
				Value: searchValue,
				IsCustomField: false,
			},
		];

		let skips = 0;
		// let top = 0;
		// const skipCount = paginationModel.pageSize || 15;
		// const limitCount = paginationModel.page * skipCount;
		// top = paginationModel.pageSize;
		// skips = limitCount;
		// setskip(skips);
		// settop(top);
		glblsrch = true;
		filterss = filters;
		setFilters(filterss);
		try {
			fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
			glblsrch = false;
			//setFilters(filters);
			//inputs.SearchInput = "";
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
		}
	};

	const columnNames = columns.map((col) => col.headerName);
	const actionColumns = ["Actions"];
	const filteredColumnNames = columnNames
		.filter((name): name is string => name !== undefined && !actionColumns.includes(name))
		.map((name) => (name === "EmailId" ? name.replace(/\s+/g, "") : name));

	// const handleApplyFilters = async (filters: any) => {
	// 	try {
	// 		isfltr = true;
	// 		filterss = filters;

	// 		const skipcount = paginationModel.pageSize || 15;
	// 		const top = paginationModel.pageSize;
	// 		const skips = paginationModel.page * skipcount;
	// 		setskip(skip);
	// 		settop(top);

	// 		// Refactor FieldName mapping with switch statement
	// 		const formattedFilters = filters.map((filter: any) => {
	// 			switch (filter.column) {
	// 				case "Email Address":
	// 					fieldName = "EmailId";
	// 					break;
	// 				case "Contact Number":
	// 					fieldName = "ContactNumber";
	// 					break;
	// 				case "Full Name":
	// 					fieldName = "FirstName";
	// 					break;
	// 				default:
	// 					fieldName = "LastName";
	// 					break;
	// 			}

	// 			return {
	// 				FieldName: fieldName,
	// 				ElementType: "string",
	// 				Condition: filter.operator,
	// 				Value: filter.value,
	// 				IsCustomField: false,
	// 			};
	// 		});
	// 		setFilters(formattedFilters);

	// 		fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, formattedFilters);
	// 	} catch (error) {
	// 		console.error("Failed to fetch user roles:", error);
	// 	}
	// };
	//////22222222222222222222222222
	const handleApplyFilters = async (filters: any) => {
		try {
			isfltr = true;
			filterss = filters;
	
			const skipcount = paginationModel.pageSize || 15;
			const top = paginationModel.pageSize;
			const skips = paginationModel.page * skipcount;
			setskip(skips);
			settop(top);
	
			// Format filters based on selected field names and conditions
			let formattedFilters = filters.flatMap((filter: any) => {
				let resultFilters = [];
				const filterValue = filter.value.trim(); // Normalize the filter value
	
				switch (filter.column) {
					case "Email Address":
						resultFilters.push({
							FieldName: "EmailId",
							ElementType: "string",
							Condition: filter.operator,
							Value: filterValue,
							IsCustomField: false,
						});
						break;
					case "Contact Number":
						resultFilters.push({
							FieldName: "ContactNumber",
							ElementType: "string",
							Condition: filter.operator,
							Value: filterValue,
							IsCustomField: false,
						});
						break;
					case "Full Name":
						resultFilters.push({
							FieldName: "UserName",
							ElementType: "string",
							Condition: filter.operator,
							Value: filterValue,
							IsCustomField: false,
						});
						break;
					default:
						const fieldName = filter.column.replace(" ", "");
						resultFilters.push({
							FieldName: fieldName,
							ElementType: "string",
							Condition: filter.operator,
							Value: filterValue,
							IsCustomField: false,
						});
						break;
				}
	
				return resultFilters;
			});
	
			setFilters(formattedFilters);
	
			// Call the API with the formatted filters
			 await fetchUsersList(
				setModels,
				setLoading,
				OrganizationId,
				skips,
				top,
				setTotalcount,
				orderByFields,
				formattedFilters
			);
	
			// Set models to the fetched data or empty array if no data is returned
			//setModels(models.length > 0 ? models : []);
		} catch (error) {
			console.error("Failed to fetch user roles:", error);
			setModels([]); // Clear models in case of an error to display "No rows"
		} finally {
			isfltr = false;
		}
	};
	
	
	
	
	
	
	
	
	// const handleApplyFilters = async (filters: any) => {
	// 	try {
	// 		isfltr = true;
	// 		filterss = filters;
	
	// 		const skipcount = paginationModel.pageSize || 15;
	// 		const top = paginationModel.pageSize;
	// 		const skips = paginationModel.page * skipcount;
	// 		setskip(skips);
	// 		settop(top);
	
	// 		// Refactor FieldName mapping with switch statement
	// 		let formattedFilters = filters.flatMap((filter: any) => {
	// 			let fieldName = '';
	// 			let resultFilters = [];
	
	// 			switch (filter.column) {
	// 				case "Email Address":
	// 					fieldName = "EmailId";
	// 					break;
	// 				case "Contact Number":
	// 					fieldName = "ContactNumber";
	// 					break;
	// 				case "Full Name":
	// 					// Split Full Name into FirstName and LastName filters
	// 					resultFilters.push({
	// 						FieldName: "FirstName",
	// 						ElementType: "string",
	// 						Condition: filter.operator,
	// 						Value: filter.value,
	// 						IsCustomField: false,
	// 					});
	// 					resultFilters.push({
	// 						FieldName: "LastName",
	// 						ElementType: "string",
	// 						Condition: filter.operator,
	// 						Value: filter.value,
	// 						IsCustomField: false,
	// 					});
	// 					break;
	// 				default:
	// 					fieldName = "LastName";
	// 					break;
	// 			}
	
	// 			// Add filter if fieldName is set (excluding "Full Name")
	// 			if (fieldName && filter.column !== "Full Name") {
	// 				resultFilters.push({
	// 					FieldName: fieldName,
	// 					ElementType: "string",
	// 					Condition: filter.operator,
	// 					Value: filter.value,
	// 					IsCustomField: false,
	// 				});
	// 			}
	
	// 			return resultFilters;
	// 		});
	
	// 		setFilters(formattedFilters);
	
	// 		fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, formattedFilters);
	// 	} catch (error) {
	// 		console.error("Failed to fetch user roles:", error);
	// 	}
	// };
	

	// if (models.length === 0 && isfltr) {
	// 	const formattedFilters = filterss.map((filter: any) => ({
	// 		FieldName: "UserName",
	// 		ElementType: "string",
	// 		Condition: filter.operator,
	// 		Value: filter.value,
	// 		IsCustomField: false,
	// 	}));

	// 	fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, formattedFilters);

	// 	isfltr = false;
	// }

	const onPageChange = (newPage: number) => {};
	const onPageSizeChange = (newPageSize: number) => {};

	const fetchusers = (skip: any, top: any) => {
		setLoading(true);
		fetchUsersList(setModels, setLoading, OrganizationId, skip, top, setTotalcount, orderByFields, filters);
	};

	const fetchData = async () => {
		const skipcount = paginationModel.pageSize || 15;
		const limitcount = paginationModel.page * skipcount;
		const skips = glblsrch ? 0 : limitcount;
		const top = paginationModel.pageSize;
		setskip(skips);
		settop(top);
		await fetchUsersList(setModels, setLoading, OrganizationId, skips, top, setTotalcount, orderByFields, filters);
	};

	useEffect(() => {
		fetchData();
	}, [paginationModel.page,paginationModel.pageSize]);

	useEffect(() => {
		if (!models.length) {
			setUsersEmails(models.map((user) => user.EmailId));
		}
	}, [models]);

	const handleNextButtonClick = () => {
		const newSkip = (paginationModel.page + 1) * paginationModel.pageSize;
		const newTop = newSkip + paginationModel.pageSize;
		setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
		fetchusers(newSkip, newTop);

	};

	const handlePreviousButtonClick = () => {
		const newSkip = (paginationModel.page - 1) * paginationModel.pageSize;
		const newTop = newSkip + paginationModel.pageSize;
		setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }));
		fetchusers(newSkip, newTop);
	};

	useEffect(() => {
		if (models.length !== 0) {
			setLoading(false);
		}
	}, [models]);
	const [showPopup, setShowPopup] = useState(false);
	const [showeditPopup, setShowEditPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);

	const openPopup = () => {
		setShowPopup(true);
	};
	const openEditPopup = () => {
		setShowEditPopup(true);
	};
	const handleDownloadExcel = async () => {
		try {
		} catch (error) {
			console.error("Error downloading Excel:", error);
		}
	};
	const CustomToolbar: React.FC<any> = () => {
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

		const handleExportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(event.currentTarget);
		};

		const handleExportMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDownloadExcelClick = () => {
			handleExportMenuClose();
		};

		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
				<Button
					aria-controls="export-menu"
					aria-haspopup="true"
					onClick={handleExportMenuClick}
					style={{ marginLeft: "10px" }}
					startIcon={<SaveAltIcon />}
				>
					Export
				</Button>
				<Menu
					id="export-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleExportMenuClose}
				>
					<MenuItem
						onClick={() => {
							handleDownloadExcel();
							handleExportMenuClose();
						}}
					>
						Download Excel
					</MenuItem>
				</Menu>
			</div>
		);
	};

	const handleDeleteuser = () => {
		try {
			DeleteUserDetails(emailiddelete, setShowDeletePopup, fetchUserDataFromApi, setModels, setLoading);
			openSnackbar("User  Deleted successfully!", "success");
		} catch (error) {
			openSnackbar("Failed to Delete user .", "error");
		}
	};

	const handleChange = (event: any) => {
		setFirstName(event.target.value);
		handleFirstnameFocus();
		const name = event.target.name;
		const value = event.target.value;
		setInputs((values) => ({ ...values, [name]: value }));
	};
	const handleSubmituser = (event: any) => {
		event.preventDefault();
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();

	};

	useEffect(() => {
		const unsubscribe = subscribe(setSidebarOpen);
		return () => unsubscribe();
	}, []);
	const onChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {};
	const handlePageChange = (newPage: number, newPageSize: number) => {};

	const handlePageSizeChange = (newPageSize: number) => {};

	const filteredRows = models.filter((row: any) => {
		const emailId = row.EmailId || "";
		const contactnumber = row.ContactNumber || "";
		const userName = row.UserName || "";

		return (
			emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			contactnumber.toLowerCase().includes(searchText.toLowerCase()) ||
			userName.toLowerCase().includes(searchText.toLowerCase())
		);
	});	
	const { t: translate } = useTranslation();
	return (
		<Container>
			<div className="qadpt-midpart setng-box">
				<div className="qadpt-content-block">
					<div className="qadpt-head">
						<div className="qadpt-title-sec">
							<div className="qadpt-title">{translate('Team')}</div>
							<div className="qadpt-description">{translate('View & Manage your teams')}</div>
						</div>
						<div className="qadpt-right-part">
							<button
								onClick={openPopup}
								className="qadpt-memberButton"
							>
								<i className="fal fa-add-plus"></i>
								<span>{translate('Create Member')}</span>
							</button>
						</div>
						<div></div>
					</div>

					<div>
						<Box className="qadpt-content-box">
							<div className="qadpt-src-flt">
								<div>
									<TextField
										className={`qadpt-teamsearch ${errors.SearchInput ? "qadpt-teamsearch-error" : ""}`}
										name="SearchInput"
										value={inputs.SearchInput}
										onChange={(e) => {
											const newValue = e.target.value;
											setInputs({ ...inputs, SearchInput: newValue });
											if (newValue === "") {
												clearSearch();
											
											}
										}}
										placeholder={translate('Search Team')}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
											  globalhandleSearch(inputs.SearchInput);
											}
										  }}
										error={!!errors.SearchInput}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<IconButton
														aria-label="search"
														
														onClick={() => globalhandleSearch(inputs.SearchInput)}
														onMouseDown={(event) => event.preventDefault()}
													>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											),
											endAdornment: inputs.SearchInput && (
												<InputAdornment position="end">
													<IconButton
														aria-label="clear"
														onClick={() => {
															setInputs({ ...inputs, SearchInput: "" });
															clearSearch();
															
														}}
													>
														<ClearIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>{" "}
								<div className="qadpt-usrfilter">
									<FilterPopup
										columns={filteredColumnNames}
										onApplyFilters={handleApplyFilters}
									/>
								</div>
							</div>
							<div style={{ position: "relative", top: "-110px" }}>
								{selectedRows.length > 0 && (
									<div style={{ marginBottom: "-15px", display: "flex", gap: "10px" }}>
										<FormControlLabel
											style={{ border: "1px black", borderRadius: "0px" }}
											control={
												<Tooltip
													title="Reset Password"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="reset password"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<KeyIcon style={{ transform: "rotate(90deg)" }} />
															<span>Reset Password</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Lock Accounts"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="lock accounts"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<LockOutlinedIcon />
															<span>
																Lock {selectedRows.length !== 0 ? `${selectedRows.length} rows selected` : ""} user
															</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Unblock User"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="unblock user"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<LockOpenOutlinedIcon />
															<span>Unblock User</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>

										<FormControlLabel
											control={
												<Tooltip
													title="Disable Account"
													arrow
													placement="top"
												>
													<IconButton
														color="secondary"
														aria-label="disable account"
													>
														<div
															className="qadpt-actionpopup"
															style={{ display: "flex", alignItems: "center", gap: "8px" }}
														>
															<NoAccountsOutlinedIcon />
															<span>Disable Account</span>
														</div>
													</IconButton>
												</Tooltip>
											}
											label=""
										/>
									</div>
								)}
							</div>
							<DataGrid
								className="qadpt-setting-grd"

								rows={models.length > 0 ? models : []}
								columns={columns}
								pagination
								paginationMode="server"
								slots={{
									columnMenu: (menuProps) => {
										if (
											menuProps.colDef.field === "EmailId" ||
											menuProps.colDef.field === "ContactNumber" ||
											menuProps.colDef.field === "AdminDeactivated"
										) {
											return (
												<UserCustomColumnMenu
													column={menuProps.colDef.field}
													setModels={setModels}
													setLoading={setLoading}
													skip={skip}
													top={top}
													OrganizationId={OrganizationId}
													setTotalcount={setTotalcount}
													orderByFields={orderByFields}
													filters={filters}
													setFilters={setFilters}
													{...menuProps}
													options={models.map((model: any) => model[menuProps.colDef.field] || "")}
													onSearch={handleSearch}
													searchText={searchText}
													setSearchText={setSearchText}
													selectedOptions={selectedOptions}
													setSelectedOptions={setSelectedOptions}
												/>
											);
										}
										return null;
									},
								}}
								getRowId={(row) =>
									row.AccountId ||
									row.Account_ID ||
									row.AccountName ||
									row.id ||
									row.Created_Date ||
									row.UserId ||
									row.Id ||
									row.OrganizationId ||
									row.ImageId
								}
								paginationModel={paginationModel}
								onPaginationModelChange={(newModel) => {
									if (models.length < paginationModel.pageSize && newModel.page > paginationModel.page) {
										return;
									}
									setPaginationModel(newModel);
								}}
								rowCount={totalcount}
								pageSizeOptions={[15, 25, 50, 100]}
								localeText={{
									MuiTablePagination: {
										labelRowsPerPage: "Records Per Page",
									},
								}}
								disableRowSelectionOnClick={true}
								sortModel={sortModel}
								onSortModelChange={handleSortModelChange}
								onRowSelectionModelChange={handleSelectionChange}
							/>

							{models.length === 0 && isfltr && (
								<div className="qadpt-loaderstyles">
									<img
										src={loader}
										alt="Spinner"
										className="qadpt-loaderSpinnerStyles"
									/>
								</div>
							)}
						</Box>
					</div>

					{unBlockUserPopup ? (
						<UserUnblock
							unBlockUserPopup={unBlockUserPopup}
							setUnBlockUserPopup={setUnBlockUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{blockUserPopup ? (
						<Userblock
							blockUserPopup={blockUserPopup}
							setBlockUserPopup={setBlockUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{disableUserPopup ? (
						<UserDisable
							disableUserPopup={disableUserPopup}
							setDisableUserPopup={setDisableUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}
					{enableUserPopup ? (
						<UserEnable
							enableUserPopup={enableUserPopup}
							setEnableUserPopup={setEnableUserPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}

					{showPopup ? (
						<UserCreate
							setModels={setModels}
							setLoading={setLoading}
							setShowPopup={setShowPopup}
							setShowEditPopup={setShowEditPopup}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}

					{showeditPopup ? (
						<EditUser
							showEditPopup={showeditPopup}
							setShowEditPopup={setShowEditPopup}
							userId={useridedit}
							fetchUserDataFromApi={fetchUserDataFromApi}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							orderByFields={orderByFields}
							filters={filters}
						/>
					) : (
						""
					)}
					{showResetPasswordPopup ? (
						<UserPasswordReset
							showResetPasswordPopup={showResetPasswordPopup}
							setShowResetPasswordPopup={setShowResetPasswordPopup}
							userId={useridedit}
							fetchUsersList={fetchUsersList}
							setModels={setModels}
							setLoading={setLoading}
							skip={skip}
							top={top}
							OrganizationId={OrganizationId}
							setTotalcount={setTotalcount}
							filters={filters}
						/>
					) : (
						""
					)}

					{showDeletePopup ? (
						<div>
							<div></div>
							<div className="userDelete-popup">
								<div>
									<svg
										onClick={() => setShowDeletePopup(false)}
										className="close-icon"
										xmlns="http://www.w3.org/2000/svg"
										x="0px"
										y="0px"
										width="24"
										height="24"
										viewBox="0 0 50 50"
									>
										<path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
									</svg>
								</div>
								<div className="qadpt-deletepopupwidth">
									<p>Are you sure you want to delete User</p>
									<button
										className="qadpt-deletepopupCancelbutton"
										onClick={() => setShowDeletePopup(false)}
									>
										Cancel
									</button>

									<button
										className="qadpt-userButton"
										onClick={handleDeleteuser}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</Container>
	);
};

export default UserList;
